import ApiError from '../api/ApiError';
import {ServiceError, ServiceErrors} from '../types';
import truncate from './truncate.ts';

function serviceErrorToString(serviceError: ServiceError): string {
    const parts: string[] = [];

    if (serviceError.message) {
        parts.push(serviceError.message);
    }

    if (serviceError.detail) {
        parts.push(serviceError.detail);
    }

    if (serviceError.stackTrace) {
        parts.push(serviceError.stackTrace);
    }

    if (serviceError.exceptionType) {
        parts.push(serviceError.exceptionType);
    }

    if (serviceError.innerException) {
        parts.push(serviceErrorToString(serviceError.innerException));
    }

    return parts.join(' - ');
}

function unknownToString(obj: unknown): string {
    if (typeof obj === 'string') {
        return obj;
    }

    if (typeof obj === 'object' && obj !== null) {
        const maybeApiErrorWithMessage = obj as {message?: string};

        if (maybeApiErrorWithMessage.message) {
            return maybeApiErrorWithMessage.message;
        } else if ('errors' in obj && 'count' in obj) {
            const serviceErrors = obj as ServiceErrors;
            return serviceErrors.errors.map(serviceErrorToString).join('\n');
        } else {
            return Object.entries(obj)
                .map(([key, value]) => `${key}: ${unknownToString(value)}`)
                .join(', ');
        }
    }

    return 'An unexpected error occurred.';
}

export default function unknownErrorToString(err: unknown) {
    if (typeof err === 'string') {
        return err;
    }

    if (err instanceof ApiError) {
        if (err.status == 429) {
            return err.message + ' Too many requests. Please try again later.';
        }

        if (err.status == 401) {
            return (
                err.message +
                ' Unauthorized. ' +
                truncate(unknownToString(err.data), 150)
            );
        }

        if (
            err.data &&
            typeof err.data === 'object' &&
            'errors' in err.data &&
            'count' in err.data
        ) {
            const serviceErrors = err.data as ServiceErrors;
            return serviceErrors.errors.map(serviceErrorToString).join('\n');
        }

        return err.message + ' ' + truncate(unknownToString(err.data), 150);
    }

    if (err instanceof Error) {
        return err.message;
    }

    return unknownToString(err);
}
