import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faEdit,
    faUserTimes,
    faUserCheck,
    faUserClock,
    faTimesCircle,
    faUserPlus
} from '@fortawesome/free-solid-svg-icons';
import {toast} from 'react-toastify';
import {useMutation} from 'react-query';

import useRemoveGroupMember, {
    RemoveGroupMemberCommand
} from '../../api/groups/useRemoveGroupMember';
import useUpdateGroupMember, {
    UpdateGroupMemberCommand
} from '../../api/groups/useUpdateGroupMember';

import {
    CollectionInfoModel,
    CollectionModel,
    CollectionType,
    MembershipStatus,
    UserCollectionType
} from '../../api/types';
import unknownErrorToString from '../../lib/unknownErrorToString';
import {useAuthStateManager} from '../../hooks/useAuthStateManager.tsx';
import OverlayWithSpinner from '../shared/OverlayWithSpinner.tsx';
import RequestToJoinModal from './RequestToJoinModal.tsx';
import RoleNames from '../../lib/RoleNames.ts';

interface GroupMemberActionIconsProps {
    collection: CollectionModel | CollectionInfoModel;
    userCollectionType?: UserCollectionType | null;
    membership?: MembershipStatus | null;
    onChanged: () => Promise<void>;
    cssClass?: string;
    showText: boolean;
    edit?: () => void;
}

const GroupMemberActionIcons: React.FC<GroupMemberActionIconsProps> = ({
    collection,
    userCollectionType,
    membership,
    onChanged,
    cssClass,
    showText,
    edit
}) => {
    const collectionId = collection.id!;
    const {loggedInUserId: userId, isInRole} = useAuthStateManager();
    const [showRequestToJoinModal, setShowRequestToJoinModal] = useState(false);

    const userIsAdmin = isInRole(RoleNames.Admin);
    const isGroupAdmin = (() => {
        if (collection.collectionType === CollectionType.User) {
            return false;
        }

        return (
            (userIsAdmin &&
                collection.loggedInUserMembershipStatus !==
                    MembershipStatus.Invited) ||
            (collection.loggedInUserUserCollectionType ===
                UserCollectionType.Admin &&
                collection.loggedInUserMembershipStatus ===
                    MembershipStatus.Member)
        );
    })();

    // --- Setup Mutations ---
    const removeGroupMember = useRemoveGroupMember();
    const updateGroupMember = useUpdateGroupMember();

    const removeGroupMemberMutation = useMutation(removeGroupMember, {
        onSuccess: () => {
            toast.success('Membership removed / request cancelled');
            onChanged?.();
        },
        onError: err => {
            toast.error(
                `Failed to remove member: ${unknownErrorToString(err)}`
            );
        }
    });

    const updateGroupMemberMutation = useMutation(updateGroupMember, {
        onSuccess: (_, variables) => {
            let msg = '';
            switch ((variables as UpdateGroupMemberCommand).membershipStatus) {
                case MembershipStatus.InviteIgnored:
                    msg = 'Invite ignored';
                    break;
                case MembershipStatus.Member:
                    msg = 'Membership accepted/request approved';
                    break;
                case MembershipStatus.RequestIgnored:
                    msg = 'Request ignored';
                    break;
                default:
                    msg = 'Membership updated';
            }
            toast.success(msg);
            onChanged?.();
        },
        onError: (err, variables) => {
            let actionStr = '';
            switch ((variables as UpdateGroupMemberCommand).membershipStatus) {
                case MembershipStatus.InviteIgnored:
                    actionStr = 'ignore invite';
                    break;
                case MembershipStatus.Member:
                    actionStr = 'accept membership';
                    break;
                case MembershipStatus.RequestIgnored:
                    actionStr = 'ignore request';
                    break;
                default:
                    actionStr = 'update membership';
            }
            toast.error(`Failed to ${actionStr}: ${unknownErrorToString(err)}`);
        }
    });

    // --- Helpers ---
    const getText = (s: string) => (showText ? s : '');

    // Example internal handlers:
    const handleIgnoreInvite = async () => {
        if (
            !collectionId ||
            !userId ||
            userCollectionType === null ||
            userCollectionType === undefined
        ) {
            return;
        }

        // For invites: membershipStatus=InviteIgnored
        const command: UpdateGroupMemberCommand = {
            collectionId,
            userId,
            userCollectionType,
            membershipStatus: MembershipStatus.InviteIgnored
        };
        await updateGroupMemberMutation.mutateAsync(command);
    };

    const handleAcceptInvite = async () => {
        if (
            !collectionId ||
            !userId ||
            userCollectionType === null ||
            userCollectionType === undefined
        ) {
            return;
        }

        const command: UpdateGroupMemberCommand = {
            collectionId,
            userId,
            userCollectionType,
            membershipStatus: MembershipStatus.Member
        };
        await updateGroupMemberMutation.mutateAsync(command);
    };

    const handleCancel = async () => {
        // For both "cancel request" or "cancel invite", we remove membership
        if (!collectionId || !userId) return;
        const command: RemoveGroupMemberCommand = {
            collectionId,
            userId
        };
        await removeGroupMemberMutation.mutateAsync(command);
    };

    const handleJoin = async () => {
        setShowRequestToJoinModal(true);
    };

    const dismissRequestToJoinModal = () => {
        setShowRequestToJoinModal(false);
    };

    // --- Render ---
    return (
        <div className={cssClass}>
            {(removeGroupMemberMutation.isLoading ||
                updateGroupMemberMutation.isLoading) && <OverlayWithSpinner />}
            {/* Example admin + membership=Member -> Edit link */}
            {edit && isGroupAdmin && membership === MembershipStatus.Member && (
                <a
                    href="#"
                    title="Edit member"
                    className="action-link"
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();

                        edit();
                    }}
                >
                    <FontAwesomeIcon icon={faEdit} />
                    {getText(' Edit')}
                </a>
            )}

            {/* For ignoring/accepting invites/requests */}
            {(isGroupAdmin && membership === MembershipStatus.Requested) ||
            (!isGroupAdmin && membership === MembershipStatus.Invited) ? (
                <>
                    <a
                        href="#"
                        title="Ignore membership"
                        className="action-link"
                        style={{color: 'darkred'}}
                        onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleIgnoreInvite();
                        }}
                    >
                        <FontAwesomeIcon icon={faUserTimes} />
                        {getText(' Ignore')}
                    </a>
                    <a
                        href="#"
                        title="Accept membership"
                        className="action-link"
                        style={{color: 'seagreen'}}
                        onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleAcceptInvite();
                        }}
                    >
                        <FontAwesomeIcon icon={faUserCheck} />
                        {getText(' Accept')}
                    </a>
                    <FontAwesomeIcon
                        icon={faUserClock}
                        style={{padding: '5px'}}
                        title="Pending membership"
                    />
                    {getText(' Pending')}
                </>
            ) : null}

            {/* For canceling membership invites/requests */}
            {(isGroupAdmin && membership === MembershipStatus.Invited) ||
            (!isGroupAdmin && membership === MembershipStatus.Requested) ? (
                <>
                    <a
                        href="#"
                        title="Cancel"
                        className="action-link"
                        style={{color: 'darkred'}}
                        onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleCancel();
                        }}
                    >
                        <FontAwesomeIcon icon={faTimesCircle} />
                        {getText(' Cancel')}
                    </a>
                    <FontAwesomeIcon
                        icon={faUserClock}
                        style={{padding: '5px'}}
                        title="Pending membership"
                    />
                    {getText(' Pending')}
                </>
            ) : null}

            {/* For a non-member wanting to join */}
            {!isGroupAdmin && membership === null && (
                <a
                    href="#"
                    title="Request to join"
                    className="action-link"
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleJoin();
                    }}
                >
                    <FontAwesomeIcon icon={faUserPlus} />
                    {getText(' Join')}
                </a>
            )}

            {showRequestToJoinModal && (
                <RequestToJoinModal
                    dismiss={dismissRequestToJoinModal}
                    onRequested={onChanged}
                    collection={collection}
                    nicknameOrEmailOrUserId={userId!}
                />
            )}
        </div>
    );
};

export default GroupMemberActionIcons;
