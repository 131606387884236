import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faUsersCog,
    faPlusCircle,
    faEye
} from '@fortawesome/free-solid-svg-icons';
import {UserCollectionType} from '../../api/types.ts';

interface GroupRoleIconProps {
    loggedInUserUserCollectionType?: UserCollectionType | null;
    cssClass?: string;
    showText?: boolean;
}

const GroupRoleIcon: React.FC<GroupRoleIconProps> = ({
    loggedInUserUserCollectionType,
    cssClass,
    showText
}) => {
    const getText = (s: string) => (showText ? s : '');

    return (
        <>
            {loggedInUserUserCollectionType === UserCollectionType.Admin && (
                <span className={cssClass}>
                    <FontAwesomeIcon
                        icon={faUsersCog}
                        className="text-secondary"
                    />
                    {getText(' Admin')}
                </span>
            )}
            {loggedInUserUserCollectionType ===
                UserCollectionType.Contributor && (
                <span className={cssClass}>
                    <FontAwesomeIcon icon={faPlusCircle} />
                    {getText(' Contributor')}
                </span>
            )}
            {loggedInUserUserCollectionType === UserCollectionType.Viewer && (
                <span className={cssClass}>
                    <FontAwesomeIcon icon={faEye} />
                    {getText(' Viewer')}
                </span>
            )}
        </>
    );
};

export default GroupRoleIcon;
